import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2, H3 } from "../components/Heading"
import { LAYOUT, LINKS } from "../constants"
import mbeAwardImage from "../assets/img/ourAwards/mbe.png"
import polAwardImage from "../assets/img/ourAwards/pol.png"

const AWARDS_DATA = [
  {
    key: "mbe",
    heading: "The Queens Birthday List MBE",
    description: (
      <>
        We were recognised on the{" "}
        <a
          href={LINKS.QUEENS_BD_HONOURS_LIST}
          target={"_blank"}
          rel={"noopener"}
        >
          Queen’s Birthday Honour list
        </a>{" "}
        2020 and awarded an MBE for our services to fundraising during Covid-19.
      </>
    ),
  },
  {
    key: "pol",
    heading: "The Point of Light Award",
    description: (
      <>
        The Prime Minister’s Office awarded us the{" "}
        <a href={LINKS.POINTS_OF_LIGHT} target={"_blank"} rel={"noopener"}>
          Point of Light Award
        </a>{" "}
        for supporting our communities through the battle with Covid-19.
      </>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const Heading = styled(H2)`
  margin-bottom: 50px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 80px;
  }
`

const AwardBoxConainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 20px;
`

const AwardBox = styled.div`
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
    max-width: 312px;
    margin-left: 15px;
    margin-right: 15px;
    max-width: 490px;

    margin-bottom: 0;
  }
`

const StatBoxHeading = styled(H3)`
  font-size: 2.4rem;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    text-align: left;
  }
`

const StatBoxDescription = styled(BodyText)`
  text-align: center;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    text-align: left;
  }
`

interface IAwardImage {
  fluid: object
  fadeIn: boolean
}

const AwardImage = styled(Img)<IAwardImage>`
  max-width: 207px;
  margin-bottom: 20px;
  width: 100%;
  flex-shrink: 0;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 0;
  }
`

const AwardBoxCopyContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-left: 33px;
  }
`

interface IOurAwards {
  rest?: object
}

const OurAwards: React.FC<IOurAwards> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query OurAwardsQuery {
      mbe: file(relativePath: { eq: "ourAwards/mbe.png" }) {
        childImageSharp {
          fluid(maxWidth: 207, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      pol: file(relativePath: { eq: "ourAwards/pol.png" }) {
        childImageSharp {
          fluid(maxWidth: 207, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Heading alignment={"CENTER"} color={"GREY"}>
        Our Awards
      </Heading>

      <AwardBoxConainer>
        {AWARDS_DATA.map((data, i) => (
          <AwardBox key={i}>
            <AwardImage
              loading={"eager"}
              fadeIn={false}
              fluid={imgData[data.key].childImageSharp.fluid}
              alt={data.heading}
            />
            <AwardBoxCopyContainer>
              <StatBoxHeading alignment={"LEFT"} color={"GREY"}>
                {data.heading}
              </StatBoxHeading>
              <StatBoxDescription type={"DARK"}>
                <p>{data.description}</p>
              </StatBoxDescription>
            </AwardBoxCopyContainer>
          </AwardBox>
        ))}
      </AwardBoxConainer>
    </Root>
  )
}

export default OurAwards
