import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2, H3 } from "../components/Heading"
import { LAYOUT, FONT_FAMILIES, COLORS } from "../constants"

const STATS_DATA = [
  {
    main: "Mental Health",
    sub: "support",
  },
  {
    main: "Food delivery",
    sub: "to support long shifts",
  },
  {
    main: "1 million",
    sub: "overnight washbags",
  },
  {
    main: "400+",
    sub: "sleep pods",
  },
  {
    main: "Travel costs",
    sub: "for front-line workers",
  },
  {
    main: "1.3 million",
    sub: "nutritious meals & snacks",
  },
  {
    main: "Grants",
    sub: "for isolation key workers",
  },
  {
    main: "Volunteer",
    sub: "expenses covered",
  },
]

const Root = styled.div`
  max-width: 1140px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 60px 0 60px 0;
  }
`

const BoxContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const Box = styled.div`
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  &: last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    margin-bottom: 60px;
    width: 25%;
  }
`

const BoxHeading = styled(H3)`
  font-size: 2.4rem;
`

const BoxDescription = styled.p`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  text-align: center;
  font-size: 1.7rem;
  color: ${COLORS.WHITE};
  span {
    font-family: ${FONT_FAMILIES.MONTSERRAT};
    font-size: 1.4rem;
  }
`

const Heading = styled(H2)`
  // margin-bottom: 40px;
`

// const Description = styled(BodyText)`
//   margin-bottom: 20px;
// `

interface IOurImpactImage {
  fluid: object
}

interface IOurImpactImage {
  fluid: object
}

const OurImpactImage = styled(Img)<IOurImpactImage>`
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 250px;
  margin: 30px auto 30px auto;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 426px;
    flex-shrink: 0;
    margin-top: 0;
  }
`

interface IOurImpact {
  rest?: object
}

const OurImpact: React.FC<IOurImpact> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query OurImpactQuery {
      nhsStaff: file(relativePath: { eq: "ourImpact/nhs-staff.png" }) {
        childImageSharp {
          fluid(maxWidth: 384, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <Heading color={"WHITE"} alignment={"CENTER"}>
        Our Impact
      </Heading>
      {/* <Description type={"LIGHT"} alignment={"CENTER"}>
        <p>The 5k challenge raised funds to support NHS workers.</p>
      </Description> */}
      <OurImpactImage
        loading={"eager"}
        fadeIn={false}
        alt={"Our impact"}
        fluid={imgData.nhsStaff.childImageSharp.fluid}
      />
      <BoxContainer>
        {STATS_DATA.map((data, i) => (
          <Box key={i}>
            <BoxHeading alignment={"CENTER"} color={"WHITE"}>
              {data.main}
            </BoxHeading>
            <BoxDescription>{data.sub}</BoxDescription>
          </Box>
        ))}
      </BoxContainer>
    </Root>
  )
}

export default OurImpact
