import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { LAYOUT, FONT_FAMILIES } from "../constants"
import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"

const Root = styled.div`
  max-width: 1340px;

  display: flex;
  padding: 50px 0 50px 0;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    padding: 60px 0;
  }
`

interface IImage {
  fluid: object
}

const Image = styled(Img)<IImage>`
  width: 100%;
  flex-shrink: 0;
  max-width: 150px;
  align-self: center;
  margin-bottom: 20px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    margin-right: 30px;
    max-width: 202px;
    margin-bottom: 0;
  }
`

const Title = styled.p`
  font-family: ${FONT_FAMILIES.RFH_FINE};
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 10px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    font-size: 2.2rem;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    margin-bottom: 0;
    text-align: left;
  }
`

const TeamMemberContainer = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 50px;
  align-items: center;
  flex-direction: column;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: calc(50% - 32px);
    margin-bottom: 80px;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    flex-direction: row;
    justify-content: flex-start;
  }
`

const WrapperInner = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  flex-wrap: wrap;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`

const Heading = styled(H2)`
  margin-bottom: 50px;
`

const CopyContainer = styled.div`
  text-align: center;
`

const Name = styled(H2)`
  text-align: center;
  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    text-align: left;
  }
`

const Bio = styled(BodyText)`
  text-align: center;
  @media (min-width: ${LAYOUT.BREAKPOINTS.XLARGE}) {
    text-align: left;
  }
`

interface IOurTeam {
  rest?: object
}

const TEAM_DATA = [
  {
    name: "Olivia",
    title: "Founder, Trustee",
    key: "olivia",
    bio:
      "With a passion for running and charity from a young age, Olivia combined the two to create Run For Heroes. Her mission is to get more people active while raising vital funds for health and wellbeing. Her favourite track to run to is Move On Up, getting her into the swing.",
  },
  {
    name: "India",
    title: "Co-Founder & Design, Trustee",
    key: "india",
    bio:
      "From the illustrations on Instagram recording the milestones of our first campaign, to this website, India is our in-house creative - Bringing colour into these dark times. India listens to an audiobook or an R&B classic whilst out for her runs.",
  },
  {
    name: "Alice",
    title: "Head of PR, Trustee",
    key: "alice",
    bio:
      "Alice is our PR-savvy media queen who is responsible for getting the word out there. Alice is your go-to contact if you want to promote Run For Heroes! She loves listening to podcasts around the lakes of Copenhagen on her runs.",
  },
  {
    name: "William",
    title: "Trustee",
    key: "william",
    bio:
      "William is a corporate lawyer at an international law firm. He frequently advises charities and non-profit institutions on a wide range of matters. His favourite running song is Through the Wire by Kanye West.",
  },
  {
    name: "Arjun",
    title: "Trustee",
    key: "arjun",
    bio:
      "After graduating from Oxford Uni, Arjun has worked in investment banking in London. His favourite track to run to is actually - podcasts! How I built this is a particular all-time fav.",
  },
  {
    name: "Lauren",
    title: "Trustee",
    key: "lauren",
    bio:
      "Lauren’s 10 year accountancy experience within the nonprofit sector meant she was the perfect woman for our trustee team. Lauren gets into the groove of running by listening to Elephant by Tame Impala.",
  },
  {
    name: "Andre",
    title: "Web developer",
    key: "andre",
    bio:
      "Andre is a our wonderful developer, bringing the Run For Heroes website to life. Try to spot our resident ninja hidden somewhere on this site, too! If you find him running, you can guess he is probably listening to The Boys Of Summer by Don Henley.",
  },
  // {
  //   name: "Andrea",
  //   title: "Intern writer",
  //   key: "andrea",
  //   bio:
  //     "Andreas is our journalist with a passion for all things running, here to bring you the latest tips and tricks to try on your next 5k. He can often be found running around Wales, gesturing dance routines from his favourite musicals. Everybody’s Talking About Jamie is his go-to running track.",
  // },
]

const OurTeam: React.FC<IOurTeam> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query OurTeamQuery {
      olivia: file(relativePath: { eq: "ourTeam/olivia.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      india: file(relativePath: { eq: "ourTeam/india.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      alice: file(relativePath: { eq: "ourTeam/alice.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      william: file(relativePath: { eq: "ourTeam/william.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      arjun: file(relativePath: { eq: "ourTeam/arjun.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      lauren: file(relativePath: { eq: "ourTeam/lauren.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      andre: file(relativePath: { eq: "ourTeam/andre.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      andrea: file(relativePath: { eq: "ourTeam/andrea.png" }) {
        childImageSharp {
          fluid(maxWidth: 202, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Heading alignment={"CENTER"} color={"GREY"}>
        Meet Our Team
      </Heading>
      <WrapperInner>
        {TEAM_DATA.map(data => (
          <TeamMemberContainer>
            <Image
              loading={"eager"}
              fadeIn={false}
              fluid={imgData[data.key].childImageSharp.fluid}
              alt={data.name}
            />
            <CopyContainer>
              <Name>{data.name}</Name>
              <Title>{data.title}</Title>
              <Bio type={"DARK"}>
                <p>{data.bio}</p>
              </Bio>
            </CopyContainer>
          </TeamMemberContainer>
        ))}
      </WrapperInner>
    </Root>
  )
}

export default OurTeam
