import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import BodyText from "../components/BodyText"
import { H2, H3 } from "../components/Heading"
import { LAYOUT } from "../constants"

const VALUES_DATA = [
  {
    heading: (
      <>
        The power of
        <br />
        community
      </>
    ),
    key: "community",
    description: (
      <>We believe in individuals coming together to create change.</>
    ),
  },
  {
    heading: (
      <>
        Inclusion & <br /> accessibility
      </>
    ),
    key: "accessibility",
    description: (
      <>We pride ourselves in developing events that are open to all.</>
    ),
  },
  {
    heading: (
      <>
        Health & <br /> wellbeing
      </>
    ),
    key: "healthWellbeing",
    description: (
      <>We motivate people to have an active & well-balanced lifestyle.</>
    ),
  },
  {
    heading: (
      <>
        Environmentally
        <br /> conscious
      </>
    ),
    key: "environmental",
    description: (
      <>We strive to make events more sustainable for a brighter future.</>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const Heading = styled(H2)`
  margin-bottom: 50px;
`

const ValuesContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const ValueBox = styled.div`
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 280px;
    margin-left: 15px;
    margin-right: 15px;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.LARGE}) {
    max-width: 190px;
  }
`

const StatBoxHeading = styled(H3)`
  font-size: 2.4rem;
  margin-bottom: 10px;
`

interface IValueBoxImage {
  fluid: object
}
const ValueBoxImage = styled(Img)<IValueBoxImage>`
  max-width: 172px;
  align-self: center;
  justify-self: center;
  margin: 0 auto 15px auto;
`

interface IOurValue {
  rest?: object
}

const OurValue: React.FC<IOurValue> = ({ ...rest }) => {
  const imgData = useStaticQuery(graphql`
    query OurValueQuery {
      accessibility: file(
        relativePath: { eq: "ourValues/inclusion-accessibility.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      community: file(relativePath: { eq: "ourValues/community.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      healthWellbeing: file(
        relativePath: { eq: "ourValues/health-wellbeing.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      environmental: file(
        relativePath: { eq: "ourValues/environmentally-conscious.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Root {...rest}>
      <Heading alignment={"CENTER"} color={"WHITE"}>
        Our Values
      </Heading>
      <ValuesContainer>
        {VALUES_DATA.map((data, i) => (
          <ValueBox key={i}>
            <ValueBoxImage
              loading={"eager"}
              fadeIn={false}
              fluid={imgData[data.key].childImageSharp.fluid}
            />
            <StatBoxHeading alignment={"CENTER"} color={"WHITE"}>
              {data.heading}
            </StatBoxHeading>
            <BodyText alignment={"CENTER"}>
              <p>{data.description}</p>
            </BodyText>
          </ValueBox>
        ))}
      </ValuesContainer>
    </Root>
  )
}

export default OurValue
