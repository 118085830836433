import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"

import Divider from "../components/Divider"
import BodyText from "../components/BodyText"
import { H3 } from "../components/Heading"
import { LAYOUT, COLORS, LINKS } from "../constants"

const STATS_DATA = [
  {
    amount: "7,000,000",
    description: <>pounds raised</>,
  },
  {
    amount: "7,000,000",
    description: <>kilometres run</>,
  },
  {
    amount: "99",
    description: (
      <>
        years old was the
        <br /> oldest participant
      </>
    ),
  },
  {
    amount: "9",
    description: (
      <>
        months old was the
        <br /> youngest participant
        <br />
        <span>(Baby Otis crawled 5cm!)</span>
      </>
    ),
  },
  {
    amount: "20+",
    description: (
      <>
        countries got
        <br /> involved
      </>
    ),
  },
]

const Root = styled.div`
  max-width: 1340px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
`

const WrapperInner = styled.div`
  max-width: 906px;
  margin: 0 auto;
`

const StyledBodyText = styled(BodyText)`
  h3 {
    margin-bottom: 30px;
  }
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 470px;
    flex-shrink: 0;
    padding-left: 20px;
  }
`

const MainContentContainer = styled.div`
  margin-bottom: 50px;

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    align-items: flex-start;
  }
`

interface IOurStoryImage {
  fluid: object
}

const OurStoryImage = styled(Img)<IOurStoryImage>`
  align-self: center;
  justify-self: center;
  width: 100%;
  max-width: 250px;
  margin: 0px auto 30px auto;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    width: 468px;
    margin-bottom: 0;
    max-width: 468px;
  }
`

const Heading = styled(BodyText)`
  max-width: 720px;
  margin-bottom: 40px;

  p,
  strong {
    font-size: 2.2rem;
  }
`

interface IFiveKChallengeSummary {
  rest?: object
}

const FiveKChallengeSummary: React.FC<IFiveKChallengeSummary> = ({
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query FiveKChallengeSummaryQuery {
      ourStory: file(relativePath: { eq: "ourStory/photos.png" }) {
        childImageSharp {
          fluid(maxWidth: 468, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <Heading type={"LIGHT"} alignment={"CENTER"}>
        <p>
          Run For Heroes is an incorporated charity born in 2020 as a response
          to the COVID-19 pandemic. We raised over £7 Million for NHS Charities
          after releasing the viral Instagram challenge;{" "}
          <strong>run 5k, donate £5 and nominate 5 others.</strong>
        </p>
      </Heading>

      <WrapperInner>
        <Divider color={COLORS.WHITE} />
        <MainContentContainer>
          <OurStoryImage
            loading={"eager"}
            fadeIn={false}
            fluid={data.ourStory.childImageSharp.fluid}
            alt={"Our story"}
          />
          <StyledBodyText type={"LIGHT"}>
            <p>
              In a matter of months the campaign had spread across the world,
              raising an additional £1 million for local charities fighting the
              effects of the pandemic. Our community went onto raise an
              additional £20,000 for the Care Workers Charity by launching{" "}
              <a target={"_blank"} href={LINKS.STRAVA} rel={"noopener"}>
                Faster 5k Fridays
              </a>
              .
            </p>

            <H3 color={"WHITE"}>
              We have mobilised over 1.5 million people to get active and give
              back to society.
            </H3>

            <p>
              On our journey we are learning the power of communities coming
              together and how lots of individuals doing something small can
              create positive change.
            </p>

            <p>
              Today we are an incorporated charity and on a mission to get as
              many people active as possible while raising vital funds for
              health and wellbeing initiatives.
            </p>

            <p>
              Our goal is to make fundraisers more inclusive, more accessible
              and more sustainable, shaping a brighter future for charity
              events.
            </p>

            <p>
              Read more about our charitable purpose and projects we fund{" "}
              <Link to={"/our-partners/charity"}>here</Link>.
            </p>
          </StyledBodyText>
        </MainContentContainer>
      </WrapperInner>
      <Divider color={COLORS.WHITE} />

      <Heading type={"LIGHT"} alignment={"CENTER"}>
        <p>
          We will never ask you for large donations or to run a marathon.{" "}
          <strong>
            For us, it’s all about the participation and having fun with it
          </strong>
          .
        </p>
      </Heading>
      {/* <BodyText type={"LIGHT"} alignment={"CENTER"}>
        <p>
          Get involved in our next event, <Link to={"/5k-may"}>5k May</Link>.
        </p>
      </BodyText> */}
    </Root>
  )
}

export default FiveKChallengeSummary
