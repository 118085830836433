import React from "react"
import styled from "styled-components"
import { COLORS } from "../constants"

const Divider = styled.div`
  height: 2px;
  max-width: 908px;
  background: ${({ color = COLORS.GREY }) => color};
  margin: 0 auto;
  width: 100%;
  margin-bottom: 56px;
`
export default Divider
