import React from "react"
import styled from "styled-components"

import { H3 } from "../components/Heading"
import { LAYOUT, FONT_FAMILIES } from "../constants"

const STATS_DATA = [
  {
    amount: "1,500,000",
    description: <>participants</>,
  },
  {
    amount: "7,000,000",
    description: <>pounds raised</>,
  },
  {
    amount: "7,000,000",
    description: <>kilometres run</>,
  },
]

const Root = styled.div`
  max-width: 750px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const StatBoxContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

const StatBox = styled.div`
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;
  &: last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    max-width: 190px;
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 0;
  }
`

const StatBoxHeading = styled(H3)`
  font-size: 3.4rem;
`

const StatBoxDescription = styled.p`
  font-family: ${FONT_FAMILIES.RFH_REGULAR};
  text-align: center;
  font-size: 1.7rem;

  span {
    font-family: ${FONT_FAMILIES.MONTSERRAT};
    font-size: 1.4rem;
  }
`

interface IKeyStats {
  rest?: object
}

const KeyStats: React.FC<IKeyStats> = ({ ...rest }) => {
  return (
    <Root {...rest}>
      <StatBoxContainer>
        {STATS_DATA.map((data, i) => (
          <StatBox key={i}>
            <StatBoxHeading alignment={"CENTER"}>{data.amount}</StatBoxHeading>
            <StatBoxDescription>{data.description}</StatBoxDescription>
          </StatBox>
        ))}
      </StatBoxContainer>
    </Root>
  )
}

export default KeyStats
