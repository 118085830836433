import React, { useRef } from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { LAYOUT } from "../constants"
import BodyText from "../components/BodyText"
import { H2 } from "../components/Heading"
import iconChevronLeft from "../assets/img/ourAmbassadors/icon-chevron-left.svg"
import iconChevronRight from "../assets/img/ourAmbassadors/icon-chevron-right.svg"

const Root = styled.div`
  max-width: 908px;
  display: flex;
  padding: 60px 0;
  margin: 0 auto;
  flex-direction: column;
`

const Heading = styled(H2)`
  margin-bottom: 50px;
`

const Slide = styled.div`
  padding-right: 20px;
  pointer-events: none;
`

interface IImage {
  fluid: object
}

const Image = styled(Img)<IImage>`
  pointer-events: none;
  max-width: 100%;
`

const StyledBodyText = styled(BodyText)`
  margin-bottom: 40px;
`

const SliderControls = styled.div`
  display: none;
  width: 100px;
  margin: 30px auto 0 auto;
  @media (min-width: ${LAYOUT.BREAKPOINTS.MEDIUM}) {
    display: flex;
  }
`

interface IOurAmbassadors {
  rest?: object
}

const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 5,

  cssEase: "linear",
  centerMode: true,
  arrows: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 5,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 4,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const IMG_DATA = [
  { title: "Amy Lou Wood", key: "amyLouWood" },
  { title: "Eli Goulding", key: "ellieGoulding" },
  { title: "Mo Farah", key: "moFarah" },
  { title: "Food Medic", key: "foodMedic" },
  { title: "Jessica Ennis", key: "jessicaEnnis" },
  { title: "Jimmy Carr", key: "jimmyCarr" },
  { title: "Josh", key: "josh" },
  { title: "Ncuti Gatwa", key: "ncutiGatwa" },
  { title: "Poppy Delavigne", key: "poppyDelavigne" },
  { title: "Princess Eugenie", key: "princessEugenie" },
  { title: "Proudlock", key: "proudlock" },
  { title: "Radio1", key: "radio1" },
  { title: "Ambassador 1", key: "ambassador01" },
  { title: "Ambassador 2", key: "ambassador02" },
  { title: "Ambassador 3", key: "ambassador03" },
  { title: "Ambassador 4", key: "ambassador04" },
  { title: "Ambassador 5", key: "ambassador05" },
  { title: "Ambassador 6", key: "ambassador06" },
  { title: "Ambassador 7", key: "ambassador07" },
  { title: "Ambassador 8", key: "ambassador08" },
  { title: "Ambassador 9", key: "ambassador09" },
]

const OurAmbassadors: React.FC<IOurAmbassadors> = ({ ...rest }) => {
  const sliderRef = useRef<Slider>(null)

  const imgData = useStaticQuery(graphql`
    query OurAmbassadorsQuery {
      ambassador01: file(relativePath: { eq: "ourAmbassadors/01.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador02: file(relativePath: { eq: "ourAmbassadors/02.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador03: file(relativePath: { eq: "ourAmbassadors/03.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador04: file(relativePath: { eq: "ourAmbassadors/04.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador05: file(relativePath: { eq: "ourAmbassadors/05.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador06: file(relativePath: { eq: "ourAmbassadors/06.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador07: file(relativePath: { eq: "ourAmbassadors/07.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador08: file(relativePath: { eq: "ourAmbassadors/08.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ambassador09: file(relativePath: { eq: "ourAmbassadors/09.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      amyLouWood: file(
        relativePath: { eq: "ourAmbassadors/amy-lou-wood.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ellieGoulding: file(
        relativePath: { eq: "ourAmbassadors/ellie-goulding.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      foodMedic: file(relativePath: { eq: "ourAmbassadors/food-medic.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      jessicaEnnis: file(
        relativePath: { eq: "ourAmbassadors/jessica-ennis.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      jimmyCarr: file(relativePath: { eq: "ourAmbassadors/jimmy-carr.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      josh: file(relativePath: { eq: "ourAmbassadors/josh.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      moFarah: file(relativePath: { eq: "ourAmbassadors/mo-farah.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      ncutiGatwa: file(relativePath: { eq: "ourAmbassadors/ncuti-gatwa.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      poppyDelavigne: file(
        relativePath: { eq: "ourAmbassadors/poppy-delavigne.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      princessEugenie: file(
        relativePath: { eq: "ourAmbassadors/princess-eugenie.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      proudlock: file(relativePath: { eq: "ourAmbassadors/proudlock.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      radio1: file(relativePath: { eq: "ourAmbassadors/radio1.png" }) {
        childImageSharp {
          fluid(maxWidth: 172, pngQuality: 100, webpQuality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Root {...rest}>
      <Heading alignment={"CENTER"} color={"WHITE"}>
        Our Ambassadors
      </Heading>
      <StyledBodyText alignment={"CENTER"} type={"LIGHT"}>
        <p>
          Last year millions of you came together and ran for your heroes,
          you’re all ambassadors - but we thought a few should be given a shout
          out:
        </p>
      </StyledBodyText>

      <Slider ref={sliderRef} {...settings}>
        {IMG_DATA.map((data, i) => (
          <Slide key={i}>
            <Image fluid={imgData[data.key].childImageSharp.fluid} />
          </Slide>
        ))}
      </Slider>
      <SliderControls>
        <img
          src={iconChevronLeft}
          onClick={() => sliderRef.current.slickPrev()}
        />
        <img
          src={iconChevronRight}
          onClick={() => sliderRef.current.slickNext()}
        />
      </SliderControls>
    </Root>
  )
}

export default OurAmbassadors
