import React from "react"
import { Helmet } from "react-helmet"

import Nav from "../partials/Nav"
import PageSection from "../layout/PageSection"
import SubPageHero from "../partials/SubPageHero"
import OurImpact from "../partials/OurImpact"
import OurAwards from "../partials/OurAwards"
import FiveKChallengeSummary from "../partials/5kChallengeSummary"
import OurValues from "../partials/OurValues"
import OurTeam from "../partials/OurTeam"
import KeyStats from "../partials/KeyStats"
import Footer from "../partials/Footer"
import CookieBanner from "../partials/CookieBanner"
// import OurNextEvent from "../partials/OurNextEvent"
import OurAmbassadors from "../partials/OurAmbassadors"
import "../styles.css"

const AboutUsPage: React.FC<{}> = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>About Us | Learn About Our Journey | Run For Heroes</title>
        <meta
          name="description"
          content="Learn more about Run For Heroes, our journey, our story and how you can support us through events, fundraising, and more."
        />
        <html lang="en" />
      </Helmet>
      <Nav activePath={"/about-us"} childActivePath={"/about-us"} />
      <PageSection hasHandsBg backgroundColor={"BLUE"}>
        <SubPageHero
          heading={"About Us"}
          // subHeading={"Get involved in our next event, 5kMay"}
        />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <KeyStats />
      </PageSection>
      <PageSection backgroundColor={"BLUE"}>
        <FiveKChallengeSummary />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <OurImpact />
      </PageSection>
      <PageSection backgroundColor={"PINK"}>
        <OurValues />
      </PageSection>
      {/* <PageSection backgroundColor={"MUTED_GREY"}>
        <OurNextEvent />
      </PageSection> */}
      <PageSection backgroundColor={"MUTED_GREY"}>
        <OurAwards />
      </PageSection>
      <PageSection backgroundColor={"BLUE"}>
        <OurAmbassadors />
      </PageSection>
      <PageSection backgroundColor={"MUTED_GREY"}>
        <OurTeam />
      </PageSection>
      <PageSection backgroundColor={"GREY"}>
        <Footer />
      </PageSection>
      <CookieBanner />
    </>
  )
}

export default AboutUsPage
